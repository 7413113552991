
/* eslint-disable */

import { onMounted } from "vue";

import CloudFun, {
  Model,
  defineComponent,
  ref,
  reactive,
  computed,
  Sorting,
  SortOrder,
  Condition,
  Operator,
  LogicalConnective,
} from "@cloudfun/core";
import {
  VxeColumnPropTypes,
  VxeGridInstance,
  VxeGridProps,
  VxeFormPropTypes,
  VxeModalInstance,
} from "vxe-table";
import FileUpload from "vue-upload-component";
import "@/global-components/ckeditor/styles.css";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import "@/assets/css/wysiwyg.css";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    FileUpload,
    FileUploader,
  },
  setup() {
    const route = useRoute();
    const hotelId = ref("");
    const uploader = ref({});
    const files = ref([]);

    const model = CloudFun.current?.model;
    const grid = ref({} as VxeGridInstance);
    const loading = ref(false);
    const editingRow = ref(null);
    const keyword = ref("");
    const customFilters = ref<Condition[]>([]);
    const canEdit = true;
    const isRowSelected = computed(
      () =>
        (grid.value.getRadioRecord && grid.value.getRadioRecord()) ||
        (grid.value.getCheckboxRecords &&
          grid.value.getCheckboxRecords().length > 0)
    );
    const isEditing = computed(
      () =>
        editingRow.value != null && grid.value.isActiveByRow(editingRow.value)
    );

    const gridOptions = reactive({
      keepSource: true,
      round: true,
      border: true,
      stripe: true,
      highlightHoverColumn: true,
      highlightHoverRow: true,
      resizable: false,
      width: "100%",
      loading: false,
      customConfig: { storage: true },
      filterConfig: { remote: true },
      sortConfig: { remote: true, multiple: true },
      //editConfig: { trigger: 'manual', mode: 'row', showIcon: false, autoClear: false },
      pagerConfig: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [5, 10, 20],
        layouts: [
          "PrevJump",
          "PrevPage",
          "Jump",
          "PageCount",
          "NextPage",
          "NextJump",
          "Sizes",
          "Total",
        ],
      },

      columns: [
        // { type: "checkbox", width: 35, fixed: "left", resizable: false },

        {
          field: "Name",
          title: "房型名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "name_filter" },
        },

        // {
        //   field: "Published",
        //   title: "上架",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   resizable: false,
        //   width: "100",
        //   slots: { default: "published" },
        //   // formatter: ({ cellValue }) => (cellValue == 0 ? "否" : "是"),
        // },
        {
          // field: "Id",

          title: "庫存",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          width: 100,
          slots: { default: "items" },
          // formatter: ({ cellValue }) => (cellValue == 0 ? "否" : "是"),
        },
        {
          title: "操作",
          width: 100,
          fixed: "right",
          align: "center",
          resizable: false,
          slots: { default: "operate" },
        },
      ],
      proxyConfig: {
        message: false,
        sort: true,
        filter: true,
        props: { result: "data", total: "totalCount" },
        ajax: {
          query: (params) => {
            //params.sorts.push({ column: Column.prototype, order: 'desc', sortBy: 'Number', property:'Number' });
            //console.log(params)

            customFilters.value.push(
              new Condition("HotelId", Operator.Equal, route.query.id)
            );

            const queryParams: {
              page: number;
              pageSize: number;
              keyword: string;
              sortings?: Sorting[];
              condition: Condition;
            } = {
              page: params.page.currentPage,
              pageSize: params.page.pageSize,
              keyword: keyword.value,
              sortings: params.sorts
                .filter((e) => e.property)
                .map(
                  (e) =>
                    new Sorting(
                      e.property,
                      e.order === "desc"
                        ? SortOrder.Descending
                        : SortOrder.Ascending
                    )
                ), // sorts
              condition: new Condition(customFilters.value), // custom filters
            };

            //console.log(queryParams)
            // filters
            const filters = params.filters
              ? params.filters.filter((e) => e.values.length)
              : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach((filter) => {
                const columnCondition = new Condition();
                filter.values.forEach((subFilter) => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And)
                      columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            return new Promise((resolve, reject) =>
              model?.dispatch("hotelRoom/load", queryParams).then(
                (payload) => {
                  console.log(payload);
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "讀取失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          //   delete: (params) => {
          //     const deleteParams = { deleteRows: params.body.removeRecords };
          //     return new Promise((resolve, reject) =>
          //       model?.dispatch("hotel/save", deleteParams).then(
          //         (payload) => resolve(payload),
          //         (reason) => {
          //           CloudFun.send("error", {
          //             subject: "刪除失敗",
          //             content: reason,
          //           });
          //           reject(reason);
          //         }
          //       )
          //     );
          //   },
          //   save: (params) => {
          //     const saveParams = {
          //       insertRows: params.body.insertRecords,
          //       updateRows: params.body.updateRecords,
          //       deleteRows: params.body.removeRecords,
          //     };
          //     return new Promise((resolve, reject) =>
          //       model?.dispatch("hotel/save", saveParams).then(
          //         (payload) => {
          //           editingRow.value = null;
          //           resolve(payload);
          //         },
          //         (reason) => {
          //           CloudFun.send("error", {
          //             subject: "保存失敗",
          //             content: reason,
          //           });
          //           reject(reason);
          //         }
          //       )
          //     );
          //   },
        },
      },
    } as VxeGridProps);

    onMounted(() => {
      //   if (route.query && route.query.id) {
      //     hotelId.value = route.query.id;
      //   }
    });

    const switchChange = async (row: any, $event: any) => {
      const payload = await model?.dispatch("hotelRoom/find", row.Id);
      if (payload) {
        payload.Published = $event.value;
        await model?.dispatch("hotelRoom/update", payload);
      }
    };

    return {
      grid,
      gridOptions,
      loading,
      isEditing,
      editingRow,
      isRowSelected,
      keyword,
      customFilters,
      files,
      uploader,
      hotelId,
      canEdit,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      switchChange,
      baseUrl: `${process.env.VUE_APP_BACKEND_URL}`,
    };
  },
  methods: {
    addNewRow() {
      this.$router.push({
        path: "detail",
        query: { hotelId: this.$route.query.id },
      });
    },
    detailEdit(row: any) {
      this.$router.push({
        path: "detail",
        query: { id: row.Id, hotelId: this.$route.query.id },
      });
    },

    remove(row: any) {
      if (row && confirm("確定要刪除此筆資料嗎?")) {
        this.gridOptions.loading = true;
        this.$model.dispatch("hotelRoom/delete", row.Id).then(
          () => {
            this.grid.commitProxy("query").finally(() => {
              this.gridOptions.loading = false;
            });
          },
          (failure) =>
            this.$send("error", {
              subject: "刪除失敗",
              content: failure.message,
            })
        );
      }
    },
    items(row: any) {},
    removeSelectedRows() {
      cash("#batch-dropdown").dropdown("hide");
      const rows = this.grid.getCheckboxRecords();
      if (rows && rows.length && confirm("確定要刪除已被選擇的資料嗎?")) {
        this.grid.commitProxy("delete");
      }
    },
    changeFilterEvent(
      event: Event,
      option: VxeColumnPropTypes.Filter,
      panel: any,
      toogleChecked?: boolean
    ) {
      if (toogleChecked) option.checked = !option.checked;
      if (option.checked && option.data) {
        if (!option.value)
          option.value = new Condition("Name", Operator.Contains);
        option.value.value = option.data;
      } else option.value = option.data = undefined;
      if (toogleChecked || option.checked)
        panel.changeOption(event, !!option.data, option);
    },
  },
});
